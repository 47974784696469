import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from '@reach/router'
import SwipeableViews from 'react-swipeable-views'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import omit from 'lodash/omit'

import { emphasize, makeStyles } from '@material-ui/core/styles'
import {
	Button,
	Checkbox,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	Paper,
	TextField,
	Typography,
} from '@material-ui/core'
import { Owl as OwlIcon } from 'mdi-material-ui'

import { PricingPlanContext } from '../pricingPlanContext'
import { link } from '../sharedStyles'
import { useAsync } from '../utils/hooks'
import api from '../utils/api'
import useSearchParams from '../utils/useSearchParams'
import Layout from '../components/Layout'
import Link from '../components/Link'

const headerHeight = 96

const formSchema = inviteToken =>
	yup.object().shape({
		fullName: yup.string().trim().required('Full name is required'),
		email: yup
			.string()
			.trim()
			.email('Must be a valid email address')
			.required('Email is required'),
		teamName: inviteToken
			? yup.string()
			: yup.string().trim().required('Team name is required'),
		teamSize: inviteToken
			? yup.string()
			: yup.string().trim().required('Team size is required'),
	})

const FormInput = props => (
	<TextField fullWidth margin={'dense'} variant={'outlined'} {...props} />
)

const useStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			justifyContent: 'center',
			minHeight: `calc(100vh - ${headerHeight}px)`,
		},
		buttonWrapper: {
			padding: theme.spacing(1, 0),
		},
		completedIcon: {
			color: theme.palette.secondary.main,
			height: theme.spacing(10),
			marginBottom: theme.spacing(3),
			width: theme.spacing(10),
		},
		content: theme.mixins.gutters({
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		}),
		displayFlexCenter: {
			alignItems: 'center',
			display: 'flex',
			height: '100%',
		},
		errorMessage: {
			fontWeight: theme.typography.fontWeightMedium,
			marginBottom: theme.spacing(2),
			padding: theme.spacing(1, 2),
		},
		fullHeight: {
			height: '100%',
		},
		logo: {
			paddingRight: theme.spacing(2),
		},
		marginTop: {
			marginTop: theme.spacing(2),
		},
		link: link(theme),
		padding: { padding: theme.spacing() },
		rightSection: {
			backgroundColor: emphasize(theme.palette.background.paper, 0.85),
			height: '100%',
			width: '100%',
		},
		wrapper: theme.mixins.gutters({
			alignItems: 'center',
			//backgroundColor: 'white',
			//boxShadow: theme.shadows[2],
			//borderRadius: 6,
			display: 'flex',
			justifyContent: 'center',
			maxWidth: theme.spacing(8 * 6),
			margin: '0 auto',
			minHeight: theme.spacing(8 * 7),
			paddingBottom: theme.spacing(3),
			paddingTop: theme.spacing(3),
		}),
	}),
	{ name: 'SignUp' }
)

// eslint-disable-next-line complexity
const SignUp = ({ completed }) => {
	const { plan } = useContext(PricingPlanContext)
	const { resetSearchParams, s: token } = useSearchParams()
	const [inviteToken, setInviteToken] = useState(null)
	const [inviteTokenData, setInviteTokenData] = useState(null)
	const { formState, handleSubmit, register } = useForm({
		defaultValues: {
			teamName: plan?.title === 'Single' ? 'Single' : '',
			teamSize: plan?.title === 'Single' ? '1' : '',
			wantsNewsletter: false,
		},
		resolver: yupResolver(formSchema(inviteToken)),
	})
	const navigate = useNavigate()
	const location = useLocation()
	const { error, isLoading, run, status } = useAsync()
	const classes = useStyles()

	const { errors } = formState

	useEffect(() => {
		const fetchData = async () => {
			if (token) {
				setInviteToken(token)
				const response = await api.getSignupTokenData(
					encodeURIComponent(token)
				)
				const data = await response.json()
				setInviteTokenData(data)
				resetSearchParams()
			}
		}
		fetchData()
	}, [location.search, resetSearchParams, token])

	const submit = data => {
		if (isLoading) return

		let signUpData = { source: 'website', ...data }

		if (inviteToken) {
			signUpData = omit(
				{
					...signUpData,
					token: inviteToken,
				},
				['teamName', 'teamSize']
			)
		}

		run(api.signUp(signUpData)).then(() => {
			// Trigger google tag manager event
			if (window.dataLayer) {
				window.dataLayer.push({ event: 'signup form completed' })
			}

			// TODO: add completed state and navigate within a useEffect hook
			if (navigate) {
				navigate('/signup/completed')
			}
		})
	}

	const getRegisterProps = (name, validation = {}) => {
		const { ref, ...rest } = register(name, validation)

		return {
			inputRef: ref,
			...rest,
		}
	}

	return (
		<Layout>
			<Helmet>
				<title>Sign Up</title>
			</Helmet>

			<Container className={classes.root}>
				<div className={classes.content}>
					<Typography align={'center'} gutterBottom variant={'h3'}>
						Join {inviteTokenData?.organisationName || 'the flock'}!
					</Typography>
					<Paper className={classes.wrapper}>
						<SwipeableViews
							index={status === 'resolved' || completed ? 1 : 0}
						>
							<form onSubmit={handleSubmit(submit)}>
								{status === 'rejected' &&
								error.type !== 'EMAIL_TAKEN' ? (
									<Typography
										className={classes.errorMessage}
										align={'center'}
										variant={'subtitle1'}
									>
										Sorry, something went wrong. We have
										alerted our engineers. Please try again
										in 30 minutes.
									</Typography>
								) : null}

								{Boolean(plan) && (
									<Typography
										align={'center'}
										gutterBottom
										variant={'h5'}
									>
										{plan.title} Plan
									</Typography>
								)}
								{Boolean(
									inviteTokenData?.invitedByUserName
								) && (
									<Typography
										align={'center'}
										gutterBottom
										variant={'h5'}
									>
										{inviteTokenData?.invitedByUserName} has
										invited you to collaborate
										{inviteTokenData?.taskTitle
											? ` on ${inviteTokenData?.taskTitle}`
											: ''}
										.
									</Typography>
								)}
								<Grid item>
									<FormInput
										error={Boolean(errors.fullName)}
										helperText={errors.fullName?.message}
										name={'fullName'}
										label={'Your Full Name'}
										{...getRegisterProps('fullName')}
									/>
								</Grid>

								<Grid item>
									<FormInput
										error={Boolean(errors.email)}
										helperText={errors.email?.message}
										name={'email'}
										label={'Email Address'}
										type={'email'}
										{...getRegisterProps('email')}
									/>
								</Grid>
								{status === 'rejected' &&
								error.type === 'EMAIL_TAKEN' ? (
									<Grid item>
										<div className={classes.padding}>
											<Grid container spacing={1}>
												<Grid item>
													<span>
														Your email already
														exists!
													</span>
												</Grid>
												<Grid item>
													<Link to={'login?forgot=1'}>
														Reset password
													</Link>
												</Grid>
												<Grid item>
													<Link
														to={'/login'}
														className={
															'btn email-used-btn'
														}
													>
														Login
													</Link>
												</Grid>
											</Grid>
										</div>
									</Grid>
								) : null}

								{!inviteToken && (
									<>
										<Grid item>
											<FormInput
												error={Boolean(errors.teamName)}
												helperText={
													errors.teamName?.message
												}
												label={'Team Name'}
												{...getRegisterProps(
													'teamName'
												)}
											/>
										</Grid>
										<Grid item>
											<FormInput
												error={Boolean(errors.teamSize)}
												helperText={
													errors.teamSize?.message
												}
												type={'number'}
												label={'Team Size'}
												{...getRegisterProps(
													'teamSize'
												)}
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												control={
													<Checkbox
														id={'wantsNewsletter'}
														{...getRegisterProps(
															'wantsNewsletter'
														)}
													/>
												}
												label={'Keep me updated'}
											/>
										</Grid>
									</>
								)}
								<Grid item>
									<div className={classes.buttonWrapper}>
										<Button
											color={'primary'}
											disabled={isLoading}
											fullWidth
											size={'large'}
											type={'submit'}
											variant={'contained'}
										>
											{isLoading ? (
												<CircularProgress size={24} />
											) : (
												'Sign up'
											)}
										</Button>
									</div>
								</Grid>
								<Grid item>
									<Typography
										className={classes.marginTop}
										color={'textSecondary'}
										variant={'caption'}
									>
										100% privacy - we will never spam you!
										<br />
										Check out our{' '}
										<Link
											to={'/legal/privacy'}
											className={classes.link}
										>
											Privacy Policy
										</Link>{' '}
										for more information.
									</Typography>
								</Grid>
							</form>

							<div className={classes.displayFlexCenter}>
								<Grid
									container
									alignItems={'center'}
									direction={'column'}
								>
									<OwlIcon
										className={classes.completedIcon}
									/>
									<Typography align={'center'} variant={'h5'}>
										Thanks for signing up. We'll be in touch
										as soon as we're ready for you.
									</Typography>
								</Grid>
							</div>
						</SwipeableViews>
					</Paper>
				</div>
			</Container>
		</Layout>
	)
}

SignUp.propTypes = {
	completed: PropTypes.bool,
}

export default SignUp
